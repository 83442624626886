body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.input {
  border: 0;
  background-color: #E5E7EB;
}

.button {
  background-color: #1F2937;
  color: white;
  cursor: pointer;
  border: 1px solid #1F2937;
}

.button:hover {
  background-color: white;
  color: #1F2937;
}

.red {
  color: #FF3751;
}

.green {
  color: #50cc00;
}

.text {
  color: #111827;
}
