@import "~tachyons/css/tachyons.css";

.h-100 {
  height: 100vh;
}

@font-face {
  font-family: 'Monument Extended Regular';
  src: url('./fonts/MonumentExtended-Regular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Monument Extended Ultrabold';
  src: url('./fonts/MonumentExtended-Ultrabold.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font: 16px/1.5 "Work Sans", -apple-system, BlinkMacSystemFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  color: #1F2937;
  position: relative;
  -webkit-app-region: drag;
  overflow: scroll;
}

.headline {
  font-family: 'Monument Extended Ultrabold';
}
